var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        ref: "searchPopoverTrigger",
        staticClass: "search-form",
        attrs: { action: "" },
      },
      [
        _c("div", { staticClass: "search-bar" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localSearchQuery,
                expression: "localSearchQuery",
              },
            ],
            staticClass: "search-bar__input",
            attrs: {
              type: "text",
              name: "q",
              placeholder: "Наименование товара или артикул",
            },
            domProps: { value: _vm.localSearchQuery },
            on: {
              focus: _vm.handleFocus,
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.localSearchQuery = $event.target.value
                },
                _vm.handleInput,
              ],
              blur: _vm.handleBlur,
            },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "search-bar__btn",
            attrs: { type: "submit", value: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.gotoSearch.apply(null, arguments)
              },
            },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.isVisible
      ? _c("div", { staticClass: "search-popover" }, [
          _c("div", { staticClass: "search-popover__left" }, [
            _c(
              "div",
              {
                staticClass:
                  "search-popover__block search-popover__block_suggestions",
              },
              [
                _c(
                  "ul",
                  _vm._l(_vm.suggestions, function (suggestion, index) {
                    return _c("li", {
                      key: index,
                      domProps: {
                        innerHTML: _vm._s(_vm.highlightSuggestion(suggestion)),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectSuggestion(suggestion)
                        },
                      },
                    })
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _vm.categories.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "search-popover__block search-popover__block_categories",
                  },
                  [
                    _c("div", { staticClass: "search-popover__title" }, [
                      _vm._v("\n\t\t\t\t\tКатегории\n\t\t\t\t"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.categories, function (category, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "search-popover__category" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "search-popover__category-link",
                              attrs: { href: category.url },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "search-popover__category-img" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: category.img,
                                      alt: category.title,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "search-popover__category-title",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(category.title) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-popover__right" }, [
            _vm.products.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "search-popover__block search-popover__block_products",
                  },
                  _vm._l(_vm.products, function (product, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "search-popover__product" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "search-popover__product-link",
                            attrs: { href: product.url },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "search-popover__product-img" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: product.img,
                                    alt: product.title,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "search-popover__product-info" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "search-popover__product-title",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(product.title) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "search-popover__product-price",
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(_vm._s(product.price) + " руб."),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }