import Vue from 'vue';
import { apiRequest } from '../../../main.js'; 

function debounce(func, delay) {
	return function(...args) {
		clearTimeout(func.timer);
		func.timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

export default {
	props: {
		searchQuery: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			isVisible: false,
			suggestions: [],
			categories: [],
			relateds: [],
			products: [],
			localSearchQuery: this.searchQuery,
			isLoading: false,
		};
	},
	mounted() {
		this.localSearchQuery = this.getSearchQuery();
		this.debouncedSearch = debounce(this.performSearch, 300);
	},
	watch: {
		localSearchQuery(newQuery) {
			this.$emit('update:searchQuery', newQuery);
			this.debouncedSearch(newQuery);
		}
	},
	methods: {
		getSearchQuery() {
			const urlParams = new URLSearchParams(window.location.search);
			return urlParams.get('qs') || ""
			return urlParams.get('qs') || "";
		},
		gotoSearch() {
			if (this.localSearchQuery !== '') {
				window.location = '/cat/search?qs=' + this.localSearchQuery;
			}
		},
		selectSuggestion(suggestion) {
			this.localSearchQuery = suggestion;
			this.gotoSearch()
			this.gotoSearch();
		},
		highlightSuggestion(suggestion) {
			const regex = new RegExp(`(${this.localSearchQuery})`, 'gi');
			return suggestion.replace(regex, '<span class="search-popover__highlight">$1</span>');
		},
		handleInput() {
			if (this.localSearchQuery.length > 0) 
				this.isVisible = true;
			else
				this.isVisible = false;
			this.isVisible = this.localSearchQuery.length > 0;
		},
		handleFocus() {
			if (this.localSearchQuery.length > 0) 
				this.isVisible = true;
			this.isVisible = this.localSearchQuery.length > 0;
		},
		handleBlur() {
			setTimeout(() => {
				this.isVisible = false;
			}, 200);
		},
		performSearch(query) {
			if (query.length > 0) {
				this.isLoading = true;
				apiRequest('/cat/search_tips', {method: 'POST', body: query}).then(
					(resp) => {
						this.suggestions = resp.suggestions;
						this.products = resp.products;
						this.categories = resp.categories;
					}
				).catch(error => {
					console.error('Error fetching search tips:', error);
				}).finally(() => {
					this.isLoading = false;
				});
			}
		},
	}
};
