import "./sass/style.sass";

import Vue from 'vue';

import VueSlickCarousel from 'vue-slick-carousel';

import FilterComponent from './components/content/filter/Filter.vue';
import SortComponent from './components/content/sort/Sort.vue';
import ProductsSection from './components/content/products_section/products_section.vue';
import Quantity from './components/widgets/quantity/Quantity.vue';
import Benefits from './components/content/benefits/benefits.vue';
import Menu from './components/widgets/menu/Menu.vue';
import CartPopover from './components/widgets/cart/Cart.vue';
import Subscription from './components/content/subscription/subscription.vue';
import ProductsList from './components/content/products_list/products_list.vue';
import SearchPopover from './components/widgets/search_popover/search_popover.vue';

import Tabs from './components/widgets/tabs/Tabs.vue';
import Tab from './components/widgets/tabs/Tab.vue';

import Order from './components/order.vue';

import {createPopper} from '@popperjs/core';

const COMPONENTS = {
  'slick-carousel': VueSlickCarousel,
  'menu-component': Menu,
  'filter-component': FilterComponent,
  'sort-component': SortComponent,
  'products-section': ProductsSection,
  'products-list': ProductsList,
  'search-popover': SearchPopover,
  'quantity-input': Quantity,
  'order-form': Order,
  Benefits,
  Tabs,
  Tab,
  CartPopover,
  Subscription,
}

Vue.config.productionTip = false;

//https://euvl.github.io/vue-js-modal/Installation.html
import VModal from 'vue-js-modal';
Vue.use(VModal, {
  dynamicDefault: { draggable: false, resizable: true, scrollable: true }
});

export async function apiRequest(url, {method = 'POST', body = undefined}) {
  const resp = await fetch(url, {
    method: method,
    headers: {"Content-Type": "application/json"},
    body: body !== undefined ? JSON.stringify(body) : body
  });
  return await resp.json();
}

let app;

export function getApp() {
  return app;
}

export const propsData = {
  initCartItems: [],
  initCartTotal: 0,
  initCartIsHaveHidden: false,
  favoritesItems: [],
};

export function startup() {
  app = new Vue({
    el: "#root",
    components: COMPONENTS,
    data: {
      catalogMenuActive: false,
      catalogFilterHide: {},
      catalogSortOrder: {},
      authPopover: false,
      cartPopover: false,
      cartTotal: propsData.initCartTotal,
      cartItems: propsData.initCartItems,
      cartIsHaveHidden: propsData.initCartIsHaveHidden,
      catalogQuantity: {},
      footerTab1: false,
      footerTab2: false,
      footerTab3: false,
      profileMenu: false,
      selectedImage: '',
    },
    props: Object.keys(propsData),
    propsData: propsData,
    methods: {
      toggleCatalogMenu() {
        this.catalogMenuActive = !this.catalogMenuActive;
      },
      toggleProfileMenu() {
        this.profileMenu = !this.profileMenu;
      },
      toggleAuthPopover() {
        if (this.authPopover) {
          if (this.authPopoverInstance) {
            this.authPopoverInstance.destroy();
            this.authPopoverInstance = null;
            this.authPopover = false;
          }
        } else {
          this.authPopover = true;
          this.authPopoverInstance = createPopper(this.$refs["authPopoverTrigger"], this.$refs["authPopover"], {
            placement: 'bottom',
          });
        }
      },
      openCartPopover() {
        this.cartPopover = true;
        this.cartPopoverInstance = createPopper(this.$refs["cartPopoverTrigger"], this.$refs["cartPopover"].$el, {
          placement: 'bottom',
        });
      },
      closeCartPopover() {
        if (this.cartPopoverInstance) {
          this.cartPopoverInstance.destroy();
          this.cartPopoverInstance = null;
          this.cartPopover = false;
        }
      },
      toggleCartPopover() {
        if (this.cartPopover) {
          this.closeCartPopover();
        } else {
          this.openCartPopover();
        }
      },
      isFavorite(itemId) {
        return this.favoritesItems.some(item => item.id === String(itemId));
      },
      toggleProductFavorite(id) {
        apiRequest('/customer/item/' + String(id), {method:'POST'}).then(
          (resp) => {
            this.favoritesItems = resp
          }
        )
      },
      // favoritesClear() {
      //   apiRequest('/customer/item/clear', {method:'POST'}).then(
      //     (resp) => {
      //       console.log("clear")
      //     }
      //   )
      // },
      isInCart(itemId) {
        return this.cartItems.some(item => item.item_id === String(itemId));
      },
      catalogApplyFilter() {
        const result = {};
      
        const fmap = {};
        for (const f of this.catalogFilters) {
          fmap[f.id] = f;
        }
        const filters = Object.fromEntries(
          Object.entries(this.$refs.catalogFilter.value).filter(([_, v]) => v.length > 0)
        );
        
        if (Object.keys(filters).length === 0) {
          this.catalogFilterHide = result;
          return
        }

        for (const [itemSetId, items] of Object.entries(this.catalogFilterData)) {
          let hasVisible = true;
          for (const [itemId, fdata] of Object.entries(items)) {
            let itemMatch = [];
            for (const [fid, fval] of Object.entries(filters)) {
              const f = fmap[fid];
              if (f.type === 'checkboxes') {
                if (fval.length === 0 || fval.indexOf(fdata[fid]) !== -1) {
                  itemMatch.push(true);
                  continue;
                }
              } else if (f.type === 'range') {
                if (
                  (fval[0] === null || fval[0] === "" || fdata[fid] >= fval[0])
                  && (fval[1] === null || fval[1] === "" || fdata[fid] <= fval[1])
                ) {
                  itemMatch.push(true);
                  continue;
                }
              }
              itemMatch.push(false);
              result[itemId] = true;
              break;
            }
            if (itemMatch.every((val) => val === true))
              hasVisible = false;
          }
          if (hasVisible)
            result[itemSetId] = true;
        }

        this.catalogFilterHide = result;
      },
      catalogApplySort(sort, direction) {
        const stockDirection = (sort === 'stock' ? -1 : 1)
        const compare = (a, b) => {
          if (a[sort] < b[sort]) {
            return -1 * direction * stockDirection;
          }
          if (a[sort] > b[sort]) {
            return direction * stockDirection;
          }
          return 0;
        }

        let sortOrder = {}
        let order = 1;
        for (const { id } of [...this.catalogSortData].sort(compare)) {
          sortOrder[id] = order++;
        }
        this.catalogSortOrder = sortOrder;
      },
      catalogQuantityChange(event) {
        this.catalogQuantity[event.itemId] = event.value;
      },
      doCatalogUpdateCart(event) {
        this.catalogUpdateCart(event.itemId, event.value);
      },
      doRemoveItem(itemId) {
        this.catalogUpdateCart(itemId, 0);
      },
      catalogUpdateCart(itemId, quantity = undefined) {
        const req = {items: {}};
        if (quantity === undefined) {
            quantity = this.catalogQuantity[itemId] || 1;
        }
        req.items[itemId] = quantity;
        return apiRequest('/api/order/update', {body: req}).then(
            (resp) => {
                this.cartTotal = resp.total;
                this.cartItems = resp.items;
                this.cartIsHaveHidden = resp.is_have_hidden_products;
            }
        ).then(() => {
            if (quantity !== 0) {
              if (!this.cartPopover)
                this.openCartPopover();

              EMT.event.push({'task': 'addtocart', 'productid': itemId});
            }
            else {
              this.$emit('itemRemoved', itemId);
            }
        });
      },
      priceFormat(value) {
        const formatter = new Intl.NumberFormat('ru-RU', {});
        return formatter.format(value);
      },
      sendToModerate(orderId, decision){
        const body = {
          decision: decision,
          id: orderId,
          comment: this.$refs['revisal_comment'].value
        };
        apiRequest("/order/moderate/", { body })
          .then((resp) => (window.location = "/customer/order/"));
      },
      openModal(image) {
        this.selectedImage = image;
        this.$modal.show('modal-img');
      },
      closeModal() {
        this.$modal.hide('modal-img');
      }
    }
  })
}